<template></template>

<script setup>
import { onMounted, watch } from 'vue';

import { storeToRefs } from 'pinia';

import { useBetaStore } from '@/store/global_settings/beta';
import { useMarketStore } from '@/store/global/market';

const betaStore = useBetaStore();
const marketStore = useMarketStore();

const { selectedProject } = storeToRefs(marketStore);
betaStore.selectedProject = selectedProject.value;

onMounted(() => {
	betaStore.getBeta();
});

watch(selectedProject, () => {
	betaStore.selectedProject = selectedProject.value;
});
</script>
