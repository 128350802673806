<template>
	<svg height="20pt" viewBox="-21 0 512 512" width="20pt" xmlns="http://www.w3.org/2000/svg">
		<path d="m282.304688 426.667969c0 38.832031-31.480469 70.3125-70.316407 70.3125-38.832031 0-70.3125-31.480469-70.3125-70.3125 0-38.835938 31.480469-70.316407 70.3125-70.316407 38.835938 0 70.316407 31.480469 70.316407 70.316407zm0 0" fill="#ffa000" />
		<path
			d="m390.804688 372.375c-28.820313-25.046875-45.355469-61.675781-45.355469-100.480469v-54.65625c0-75.691406-59.882813-137.257812-133.460938-137.257812s-133.460937 61.566406-133.460937 137.257812v54.65625c0 38.804688-16.53125 75.433594-45.523438 100.628907-7.425781 6.546874-11.671875 16.0625-11.671875 26.089843 0 18.921875 14.976563 34.304688 33.367188 34.304688h314.601562c18.390625 0 33.367188-15.402344 33.367188-34.304688 0-10.027343-4.269531-19.542969-11.863281-26.238281zm0 0"
			fill="#ffc107"
		/>
		<path d="m469.332031 106.667969c0 58.910156-47.753906 106.664062-106.664062 106.664062-58.910157 0-106.667969-47.753906-106.667969-106.664062 0-58.910157 47.757812-106.667969 106.667969-106.667969 58.910156 0 106.664062 47.757812 106.664062 106.667969zm0 0" fill="#f44336" />
		<path d="m213.332031 512c-44.09375 0-80-35.882812-80-80 0-8.832031 7.167969-16 16-16s16 7.167969 16 16c0 26.476562 21.546875 48 48 48s48-21.523438 48-48c0-8.832031 7.167969-16 16-16s16 7.167969 16 16c0 44.117188-35.902343 80-80 80zm0 0" />
		<path
			d="m389.332031 448h-352c-20.585937 0-37.332031-16.746094-37.332031-37.332031 0-10.925781 4.757812-21.269531 13.054688-28.375.535156-.46875 1.109374-.894531 1.707031-1.28125 31.316406-27.328125 49.238281-66.644531 49.238281-108.160157v-59.519531c0-82.34375 67.007812-149.332031 149.332031-149.332031 3.414063 0 7.105469.0625 10.519531.640625 8.722657 1.449219 14.613282 9.707031 13.160157 18.410156-1.449219 8.726563-9.875 14.550781-18.410157 13.140625-1.707031-.253906-3.582031-.210937-5.269531-.191406-64.679687 0-117.332031 52.628906-117.332031 117.332031v59.519531c0 51.542969-22.59375 100.3125-61.929688 133.78125-.320312.257813-.597656.492188-.941406.726563-.574218.726563-1.128906 1.835937-1.128906 3.308594 0 2.898437 2.433594 5.332031 5.332031 5.332031h352c2.902344 0 5.335938-2.433594 5.335938-5.332031 0-1.496094-.554688-2.582031-1.152344-3.308594-.320313-.234375-.597656-.46875-.917969-.726563-39.359375-33.492187-61.929687-82.238281-61.929687-133.78125v-23.25c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v23.25c0 41.539063 17.941406 80.875 49.300781 108.226563.574219.382813 1.128906.789063 1.640625 1.238281 8.300781 7.082032 13.058594 17.425782 13.058594 28.351563 0 20.585937-16.746094 37.332031-37.335938 37.332031zm0 0"
		/>
		<path
			d="m362.667969 213.332031c-58.816407 0-106.667969-47.847656-106.667969-106.664062 0-58.816407 47.851562-106.667969 106.667969-106.667969 58.816406 0 106.664062 47.851562 106.664062 106.667969 0 58.816406-47.847656 106.664062-106.664062 106.664062zm0-181.332031c-41.175781 0-74.667969 33.492188-74.667969 74.667969 0 41.171875 33.492188 74.664062 74.667969 74.664062 41.171875 0 74.664062-33.492187 74.664062-74.664062 0-41.175781-33.492187-74.667969-74.664062-74.667969zm0 0"
		/>
	</svg>
</template>

<style lang="scss" scoped>
.icon {
	width: 100%;
	aspect-ratio: 1/1;
}
</style>
