// src/newrelic.js
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

const options = {
	init: {
		distributed_tracing: {
			enabled: true,
		},
		privacy: {
			cookies_enabled: true,
		},
		ajax: {
			deny_list: ['bam.eu01.nr-data.net'],
		},
	},
	info: {
		beacon: 'bam.eu01.nr-data.net',
		errorBeacon: 'bam.eu01.nr-data.net',
		licenseKey: 'NRJS-d69d6e0df8c7efc685a',
		applicationID: '538617125',
		sa: 1,
	},
	loader_config: {
		accountID: '4364630',
		trustKey: '4364630',
		agentID: '538617125',
		licenseKey: 'NRJS-d69d6e0df8c7efc685a',
		applicationID: '538617125',
	},
};

// The agent loader code executes immediately on instantiation.
new BrowserAgent(options);
