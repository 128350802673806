import { defineStore } from 'pinia';
import axios from 'axios';

export const useMaintenanceStore = defineStore('maintenance', {
	state: () => ({
		banners: [],
		closed: [],
		showed: {},
	}),
	getters: {
		dashboard: state => {
			return state.banners.filter(banner => banner.maintenance_page === 'dashboard');
		},
	},
	actions: {
		async fetchData() {
			const response = await axios.post('vuejs/get_banners', { withCredentials: true });

			this.banners = response.data.data.map(banner => {
				this.closed.push({ id: banner.id, status: false });
				return {
					id: banner.id,
					maintenance_page: banner.maintenance_page,
					maintenance_status: banner.maintenance_status == 1 ? true : false,
					maintenance_cause: banner.maintenance_cause,
				};
			});
		},
		async updateBanner(id, text) {
			const response = await axios.post(
				'vuejs/update_banner',
				{
					banner_id: id,
					text: text,
				},
				{ withCredentials: true },
			);

			this.banners.filter(banner => banner.id === id)[0].maintenance_cause = text;
		},
		async activateBanner(id) {
			const response = await axios.post(
				'vuejs/activate_banner',
				{
					banner_id: id,
				},
				{ withCredentials: true },
			);

			// this.banners.filter((banner) => banner.id === id)[0].maintenance_status = !status;
		},
	},
	persist: true,
});
