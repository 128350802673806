import { defineStore } from 'pinia';
import axios from 'axios';

export const usedevLogsStore = defineStore('dev_logs', {
	state: () => ({
		updates: [],
	}),
	actions: {
		async fetchData(selectedMarket) {
			const response = await axios.post(
				'vuejs/get_dev_logs',
				{
					market_id: selectedMarket,
				},
				{ withCredentials: true },
			);

			this.updates = response.data.data.map(update => {
				return {
					...update,
					content: JSON.parse(update.content)
				};
			});

			// this.updates = [
			// 	{
			// 		"type": "minor",
			// 		"version": "2.4.1",
			// 		"content": {
			// 			"fr": [
			// 				{
			// 					"message": "le filtres des labels sur le tableau des mots clefs ont été corriger",
			// 					"issue": "bug"
			// 				},
			// 				{
			// 					"message": "l'authentification a été améliorée",
			// 					"issue": "change"
			// 				}
			// 			],
			// 			"en": [
			// 				{
			// 					"message": "the label filters on the keywords table have been fixed",
			// 					"issue": "bug"
			// 				},
			// 				{
			// 					"message": "authentication has been improved",
			// 					"issue": "change"
			// 				}
			// 			]
			// 		},
			// 		"date": "15-10-2024"
			// 	},
			// ];
		},
		async createLog(log) {
			await axios.post('vuejs/add_dev_logs', {
				'log': log,
			},
				{ withCredentials: true });

			this.fetchData();
		},
		async updateDevLogs(logs) {
			await axios.post('vuejs/update_dev_logs', {
				'logs': logs,
			},
				{ withCredentials: true });

			this.fetchData();
		},
	},
	persist: true,
});