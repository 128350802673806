<template>
	<div class="date">
		<VueDatePicker
			v-model="date"
			:format="format"
			:enable-time-picker="false"
			month-name-format="long"
			:clearable="false"
			position="right"
			range
			multi-calendars
			:preset-ranges="presetRanges"
			close-on-scroll
			:max-date="maxDate"
			prevent-min-max-navigation
			@update:model-value="storeDates"
			:locale="locale"
		>
			<template #yearly="{ label, range, presetDateRange }" class="clendar-sidebar">
				<span @click="presetDateRange(range)">{{ label }}</span>
			</template>
			<template #action-preview="{ value }"> </template>
		</VueDatePicker>
	</div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { subDays, subMonths } from 'date-fns';

import { useKeywordsStore } from '@/store/visibility/keywords';

const KeywordsStore = useKeywordsStore();
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n({});

// date Picker
const date = ref();
const maxDate = computed(() => subDays(new Date(), 3));
const selected_dates = JSON.parse(localStorage.getItem('dashboardSelectedDates'));

if (selected_dates) {
	const formatDate = dateStr => {
		const parts = dateStr.split('-');
		const year = parseInt(parts[2], 10);
		const month = parseInt(parts[1], 10) - 1;
		const day = parseInt(parts[0], 10);
		return new Date(year, month, day);
	};

	const reformatDate = dateStr => {
		const date = new Date(dateStr);
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const year = date.getFullYear();
		return `${day}-${month}-${year}`;
	};

	const d1 = formatDate(selected_dates['to']);
	const d2 = maxDate.value;
	const endDate = d1.getTime() <= d2.getTime() ? d1 : d2;

	const ds = formatDate(selected_dates['from']);
	const startDate = ds.getTime() >= d2.getTime() ? new Date(Date.now() - 7 * 24 * 60 * 60 * 1000) : ds;

	date.value = [startDate, endDate];

	localStorage.setItem(
		'dashboardSelectedDates',
		JSON.stringify({
			from: selected_dates['from'],
			to: reformatDate(endDate),
		}),
	);
} else {
	const startDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);

	const endDate = maxDate.value;

	date.value = [startDate.toISOString(), endDate.toISOString()];

	let date_from = startDate.toISOString().substring(0, 10).split('-');
	let date_to = endDate.toISOString().substring(0, 10).split('-');

	// set default dates in localStorage
	localStorage.setItem(
		'dashboardSelectedDates',
		JSON.stringify({
			from: date_from[2] + '-' + date_from[1] + '-' + date_from[0],
			to: date_to[2] + '-' + date_to[1] + '-' + date_to[0],
		}),
	);
}

const presetRanges = computed(() => {
	return [
		{
			label: t('dashboard.calendar.last_day'),
			range: [maxDate.value, maxDate.value],
		},
		{
			label: t('dashboard.calendar.last_15_days'),
			range: [subDays(new Date(), 17), maxDate.value],
		},
		{
			label: t('dashboard.calendar.last_30_days'),
			range: [subDays(new Date(), 32), maxDate.value],
		},
		// {
		//  label: t('dashboard.calendar.this_month'),
		//  range: [startOfMonth(new Date()), subHours(new Date(getYear(new Date()), getMonth(new Date()), getDate(new Date())), 48)]
		//},
		{
			label: t('dashboard.calendar.last_3_months'),
			range: [subMonths(new Date(), 3), maxDate.value],
		},
		{
			label: t('dashboard.calendar.last_12_months'),
			range: [subMonths(new Date(), 12), maxDate.value],
		},
	];
});

const format = date => {
	const day_from = date[0].getDate();
	const month_from = date[0].getMonth() + 1;
	const year_from = date[0].getFullYear();

	const day_to = date[1].getDate();
	const month_to = date[1].getMonth() + 1;
	const year_to = date[1].getFullYear();

	return `${day_from}/${month_from}/${year_from} > ${day_to}/${month_to}/${year_to}`;
};

const storeDates = modelData => {
	const formatDate = dateStr => {
		const date = new Date(dateStr);
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const year = date.getFullYear();
		return `${day}-${month}-${year}`;
	};

	let fromDate = new Date(modelData[0]);
	let toDate = new Date(modelData[1]);

	// Swap dates if 'from' is later than 'to'
	if (fromDate > toDate) {
		[fromDate, toDate] = [toDate, fromDate];
	}

	let selected_dates = {
		from: formatDate(fromDate),
		to: formatDate(toDate),
	};

	localStorage.setItem('dashboardSelectedDates', JSON.stringify(selected_dates));
	location.reload();
};
</script>

<style lang="scss" scoped>
.date {
	min-width: 240px;
}
</style>

<style>
.dp__menu,
.dp__input_readonly {
	border-radius: 9px;
}
.dp__preset_ranges {
	min-width: 135px;
	padding: 0;
}
.dp__preset_ranges .dp__preset_range {
	padding: 0.45rem 1rem;
}
.dp__preset_ranges .dp__preset_range:first-child {
	border-radius: 9px 0 0 0;
}
.dp__cell_disabled {
	opacity: 0.4;
}
.dp__calendar {
	margin: 0 1rem;
}
.dp__flex_display .dp__instance_calendar:last-child {
	border-left: 1px solid var(--dp-border-color);
}
.dp__action_row {
	border-top: 1px solid var(--dp-border-color);
}
.dp__cell_disabled::after {
	content: '';
	position: absolute;
	top: 50%;
	left: 25%;
	width: 50%;
	height: 1px;
	background-color: #000000;
	opacity: 0.3;
}
.dp__range_start {
	background-color: #05a0cf;
	border-radius: 9px 0 0 9px;
}
.dp__range_between {
	color: #05a0cf;
}
.dp__range_end {
	background-color: #05a0cf;
	border-radius: 0 9px 9px 0;
}
.dp__today {
	border: 1px solid #05a0cf;
	border-radius: 10px;
}
.dp__select {
	margin: 0.5rem 1.2rem;
	background-color: #05a0cf;
	color: white !important;
	padding: 0.5rem 1rem !important;
	border-radius: 0.5rem;
	transition-duration: 0.6s;
}
.dp__select:hover {
	background-color: #098fb8 !important;
	transition-duration: 0.4s;
}
.dp__cancel {
	background-color: #eeeeee !important;
	color: black !important;
	padding: 0.5rem 1rem !important;
	border-radius: 0.5rem;
	transition-duration: 0.6s;
}

.dp__cancel:hover {
	background-color: #e1e1e1 !important;
	transition-duration: 0.4s;
}
.dp__calendar_header_separator,
.dp__arrow_top {
	display: none;
}
</style>
