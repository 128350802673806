<template>
	<Modal width="35vw" height="70vh" :showModal="open" @close-modal="closeModal()" :backDrop="true">
		<template v-slot:title>
			<h2>{{ t('devlogs.title') }}</h2>
		</template>
		<template v-slot:content>
			<!-- Iterate over updates -->
			<div v-for="(update, index) in updates" :key="index">
				<div class="flex justify-content-between align-center">
					<div class="flex gap-4 align-center">
						<!-- Conditionally add a class to the first icon -->
						<div :class="['icon', { firstIcon: index === 0 }]" :style="{ color: get_color(update.type) }">
							<img :style="{ height: get_icon_size(update.type) }" :src="get_icon(update.type)"
								alt="Icon" />
						</div>
						<h3 style="font-size: 1.5rem;">Version {{ update.version }}</h3>
					</div>
					<p style="color: var(--gris-semactic); font-size: 0.8rem; font-style: italic;">{{ update.date }}</p>
				</div>

				<div class="content-group" :class="{ firstContent: index === 0 }"
					:style="{ '--bar-color': get_color(update.type) }">
					<!-- Iterate over grouped content by issue type -->
					<div v-for="(group, groupKey) in getGroupedContent(update)" :key="groupKey">
						<div v-if="group.length > 0">
							<h4 class="subtitle">{{ t('devlogs.' + groupKey) }}</h4>
							<ul>
								<li v-for="(item, idx) in group" :key="idx">
									{{ item.message }}
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</template>
	</Modal>
</template>

<script setup>
// vue
import { defineProps } from 'vue';
const emit = defineEmits('updateModal');

// import
import Modal from '@/components/utilities/modal/modal.vue';
import glass_icon from '@/assets/icon/glass-type.svg';
import shield_icon from '@/assets/icon/shield-type.svg';
import star_icon from '@/assets/icon/star-type.svg';

// pinia
import { storeToRefs } from 'pinia';
import { usedevLogsStore } from '@/store/global_settings/devLogs';
const dev_logs = usedevLogsStore();
const { updates } = storeToRefs(dev_logs);

import { useI18n } from 'vue-i18n';
const { t, locale } = useI18n({});

// props
const props = defineProps({
	open: Boolean,
});

// functions
const closeModal = () => {
	emit('updateModal');
};

// Function to group content by issue type for each update
const getGroupedContent = (update) => {
	const grouped = {
		change: [],
		feature: [],
		bug: []
	};

	if (update.content && update.content[locale.value]) {
		update.content[locale.value].forEach(contentItem => {
			const issueName = contentItem.issue.name || contentItem.issue;
			switch (issueName) {
				case 'change':
					grouped.change.push(contentItem);
					break;
				case 'feature':
					grouped.feature.push(contentItem);
					break;
				case 'bug':
					grouped.bug.push(contentItem);
					break;
			}
		});
	}
	return grouped;
};

// Function to get the icon based on the type
const get_icon = (type) => {
	switch (type.name || type) {
		case 'minor':
			return shield_icon;
		case 'medium':
			return star_icon;
		case 'major':
			return glass_icon;
		default:
			return shield_icon;
	}
};

const get_icon_size = (type) => {
	switch (type.name || type) {
		case 'minor':
			return '2rem';
		case 'medium':
			return '1.7rem';
		case 'major':
			return '1.9rem';
		default:
			return '1.7rem';
	}
};

// Function to get the border color based on the type
const get_color = (type) => {
	switch (type.name || type) {
		case 'minor':
			return '#07a8da';
		case 'medium':
			return '#ffc230';
		case 'major':
			return '#ffc230';
		default:
			return '#07a8da';
	}
};
</script>

<style lang="scss" scoped>
.icon {
	position: relative;
	width: 3.5rem;
	height: 3.5rem;
	display: inline-block;
	border-radius: 50%;
	overflow: hidden;

	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: block;
	}

	/* Show border only for the first icon */
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		border: 4px solid currentColor;
		box-sizing: border-box;
		display: block;
	}

	/* Hide ::after border for non-first icons */
	&.firstIcon::after {
		display: block;
	}

	/* Hide ::after for non-first icons */
	&:not(.firstIcon)::after {
		display: none;
	}
}

.content-group {
	position: relative;
	padding: 0.5rem 0 1rem 5rem;

	h4 {
		margin: 1rem 0 0 0;
	}

	/* Add ::before bar for each content group */
	&::before {
		content: '';
		position: absolute;
		top: 7.5%;
		left: 1.6rem;
		width: 4px;
		height: 85%;
		background-color: var(--gris-semactic);
		border-radius: 5rem;
	}

	ul {
		list-style: disc;
		padding-left: 1rem;
		margin-top: 0.2rem;
		color: var(--gris-fonce-semactic);
	}

	/* Apply dynamic color to the first content-group */
	&.firstContent::before {
		background-color: var(--bar-color);
	}
}
</style>
