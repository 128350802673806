<template>
	<div class="input-switch flex align-items-center">
		<input class="input-switch__slot" type="checkbox" :checked="checked" @change="handleChange" />
		<p>
			<slot></slot>
		</p>
	</div>
</template>

<script setup>
import { ref, watchEffect, defineEmits } from 'vue';

const props = defineProps({
	modelValue: Boolean,
});

const emit = defineEmits(['update:modelValue']);

let checked = ref(props.modelValue);

watchEffect(() => {
	checked.value = props.modelValue;
});

const handleChange = event => {
	checked.value = event.target.checked;
	emit('update:modelValue', checked.value);
};
</script>

<style lang="scss" scoped>
.switch {
	margin-right: 0.4rem;
}

.input-switch {
	display: flex;
	align-items: center;

	p {
		margin: 0;
		text-align: center;
		// line-height: 10rem;
		color: var(--gris-fonce-semactic);
	}
}

.input-switch__slot {
	-webkit-appearance: none;
	margin-right: 0.5rem;
	appearance: none;
	width: 31px;
	height: 18px;
	background-color: #ddd;
	border: none;
	border-radius: 25px;
	transition: background 0.6s;
	cursor: pointer;
	position: relative;
}

/* Create the toggle */
.input-switch__slot::after {
	content: '';
	width: 0.85rem;
	height: 0.85rem;
	border-radius: 50%;
	background-color: #fff;
	display: block;
	position: absolute;
	top: 50%;
	left: 30%;
	transform: translate(-50%, -50%);
	transition: left 0.3s;
}

/* Change the background when it's checked */
.input-switch__slot:checked {
	background: #61bae0;
}

/* Change position on checked */
.input-switch__slot:checked::after {
	left: 70%;
}
</style>
