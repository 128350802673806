<template>
	<svg class="icon" width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="11.4853" cy="11.3451" r="11.0251" fill="currentColor" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M18.0761 8.06053L9.66539 16.4713L4.92969 11.7356L7.05101 9.61425L9.66539 12.2286L15.9548 5.93921L18.0761 8.06053Z" fill="#020203" />
	</svg>
</template>

<style lang="scss" scoped>
.icon {
	width: 100%;
	aspect-ratio: 1/1;
}
</style>
