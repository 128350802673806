<template>
	<svg class="icon" version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20 20" style="enable-background: new 0 0 20 20" xml:space="preserve">
		<polygon points="11.5,14.8 11.5,10.8 0.2,10.8 0.2,9.2 11.5,9.2 11.5,5.2 19.8,10 " />
	</svg>
</template>

<style lang="scss" scoped>
.icon {
	width: 100%;
	aspect-ratio: 1/1;
	fill: currentColor;
}
</style>
