<template>
	<div class="notification-content" :class="openOrClose">
		<div class="notification">
			<div class="notification__left-side">
				<div class="notification__left-side__color" :style="{ background: color }"></div>
				<img :src="icon" width="30" alt="" />
				<div class="notification__left-side__content">
					<p class="heading">{{ heading }}</p>
					<pre class="message">{{ notification.message }}</pre>
				</div>
			</div>
			<img @click="notification.close()" class="close-icon" src="@/assets/icon/close-icon.png" width="15" alt="" />
		</div>
	</div>
</template>

<script setup>
import { computed, ref, watchEffect } from 'vue';
import { useNotificationStore } from '@/store/global/notification';

// I18n
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const notification = useNotificationStore();

const color = ref('');
const icon = ref('');
const heading = ref('');

const getImageUrl = name => {
	return new URL(`../../assets/icon/${name}`, import.meta.url).href;
};

watchEffect(() => {
	switch (notification.type) {
		case 'success':
			color.value = '#21A67A';
			icon.value = getImageUrl('success-icon.png');
			heading.value = t('notif.success');
			break;
		case 'warning':
			color.value = '#FFC24E';
			icon.value = getImageUrl('warning-icon.png');
			heading.value = t('notif.warning');
			break;
		case 'info':
			color.value = '#009FE1';
			icon.value = getImageUrl('info-icon.png');
			heading.value = t('notif.info');
			break;
		case 'error':
			color.value = '#EC1B2E';
			icon.value = getImageUrl('error-icon.png');
			heading.value = t('notif.Error');
			break;
	}
});
const openOrClose = computed(() => {
	return notification.isOpen ? 'open' : 'close';
});
</script>

<style lang="scss" scoped>
.notification-content {
	position: fixed;
	z-index: 10000;
	bottom: 3%; // adjusted to bottom
	right: 2%; // adjusted to right
	opacity: 0;
	.notification {
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
		width: 400px;
		min-height: 80px;
		border-radius: 8px;
		padding: 10px 20px 10px 10px;
		background: #ffffff;
		margin-left: 20px;
		&__left-side {
			display: flex;
			align-items: center;
			gap: 20px;
			&__color {
				background: #f54336;
				width: 6px;
				height: 80px;
				border-radius: 4px;
			}
			&__content {
				.heading {
					color: #3a3f4a;
					font-weight: 500;
					font-size: 17px;
				}
				.message {
					color: #535457;
					font-weight: 400;
					opacity: 0.7;
					font-size: 17px;
					margin-top: 7px;
					word-wrap: break-word;
					white-space: normal;
				}
			}
		}
		.close-icon {
			margin-left: 10px;
			cursor: pointer;
		}
	}
}
.open {
	bottom: 3%;
	transition: opacity 400ms;
	visibility: visible;
	opacity: 1; // fully visible
}
.close {
	bottom: -10%;
	transition: 400ms;
	visibility: hidden;
}
</style>
