function getContrastColor(hexColor) {
	hexColor = hexColor.replace('#', '');

	if (hexColor.length === 3) {
		hexColor = hexColor.split('').map(c => c + c).join('');
	}

	const r = parseInt(hexColor.substring(0, 2), 16);
	const g = parseInt(hexColor.substring(2, 4), 16);
	const b = parseInt(hexColor.substring(4, 6), 16);

	const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
	return luminance > 0.7 ? '#121212' : '#F1F1F1';
}

export const fixHexColor = color => {
	try {
		return color[0] === '#' ? color : `#${color}`;
	} catch (error) {
		return color;
	}
};

export function getTagStyle(backgroundColor) {
	const fixedColor = fixHexColor(backgroundColor);
	return {
		backgroundColor: fixedColor,
		color: getContrastColor(fixedColor)
	};
}
