<template>
	<section id="notification-bell-section">
		<div class="notification-bell-container">
			<div v-if="isLoading"></div>
			<div v-else>
				<button class="bell-button" @click.stop="toggleNotifications" id="menu_show_notifications_click">
					<div v-if="nblistingLengthStatusNull == 0">
						<bell />
					</div>
					<div class="notification-bell" v-else-if="nblistingLengthStatusNull > 0">
						<bell_alert class="btn-bell" />
						<div class="my-btn-border"></div>
					</div>
				</button>

				<div class="notifications-popup" v-if="showNotifications">
					<div class="notification-list">
						<div v-if="listingLength === 0" class="no-notifications-message">No notification available.
						</div>
						<div v-else class="notification-item"
							v-for="(notification, index) in notificationListing.slice(0, displayedNotifications)"
							:key="notification.id">
							<a v-if="notification.redirect_url.startsWith('/')" :href="notification.redirect_url"
								class="notification-link" @click="handleNotificationClick(notification)"
								id="menu_see_notification_detail_click">
								<div class="notification-content">
									<h4 class="notification-title">{{ notification.title }}</h4>
									<p class="notification-description">{{ notification.description }}</p>
								</div>
							</a>
							<a v-else-if="notification.redirect_url.startsWith('()')" href="#" class="notification-link"
								@click.prevent="executeFunction(notification.redirect_url.slice(2), notification, notification.id)">
								<div class="notification-content">
									<h4 class="notification-title">{{ notification.title }}</h4>
									<p class="notification-description">{{ notification.description }}</p>
								</div>
							</a>
							<span class="notification-unread-indicator" v-if="notification.status == 0"></span>
						</div>
					</div>
					<a v-if="listingLength > displayedNotifications" href="#" class="view-more-link"
						@click.prevent="loadMoreNotifications">View more</a>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch, computed } from 'vue';
import { defineEmits } from 'vue';

const emit = defineEmits(['openDevLogsModal']);

import bell from '../icons/svg/bell_icon.vue';
import bell_alert from '../icons/svg/bell_alert_icon.vue';
import { useNotificationBellStore } from '@/store/global/notifications_listing.js';
import { storeToRefs } from 'pinia';
import { useMarketStore } from '@/store/global/market';
import { useRoute } from 'vue-router';

const route = useRoute();
const marketStore = useMarketStore();
const { selectedMarket } = storeToRefs(marketStore);
const showNotifications = ref(false);
const useNotifications = useNotificationBellStore();
const { listing, isLoading } = storeToRefs(useNotifications);
const listingLength = computed(() => (listing.value ? listing.value.length : 0));
const nblistingLengthStatusNull = computed(() => (listing.value ? listing.value.filter(item => item.status == 0).length : 0));
const notificationListing = ref(listing.value);

const displayedNotifications = ref(3); // Nombre initial de notifications à afficher

function toggleNotifications() {
	showNotifications.value = !showNotifications.value;
}

function handleNotificationClick(notification) {
	if (notification.url) {
		window.open(notification.url, '_blank');
	}
}

function loadMoreNotifications() {
	displayedNotifications.value += 3; // Charge 3 notifications supplémentaires
}

function executeFunction(functionName, notification, id) {
	if (functionName === 'OpenDevLogsModal') {
		emit('openDevLogsModal');
		useNotifications.markAsRead(id)
	}
}

function handleClickOutside(event) {
	if (!event.target.closest('.view-more-link')) {
		showNotifications.value = false;
	}
}

// Watcher pour attacher/détacher handleClickOutside en fonction de showNotifications
watch(showNotifications, newVal => {
	if (newVal) {
		// Attache l'écouteur lorsque la popup est affichée
		window.addEventListener('click', handleClickOutside);
	} else {
		// Détache l'écouteur lorsque la popup est fermée
		window.removeEventListener('click', handleClickOutside);
	}
});

// console.log("route.path:", route);
// This computed property will reactively provide the current route path
const currentPath = computed(() => route.path);

watch(
	currentPath,
	(newVal, oldVal) => {
		if (newVal !== oldVal) {
			useNotifications.fetchData(selectedMarket.value);
		}
	},
	{ immediate: true },
);
</script>

<style scoped>
.bell-button {
	background: none;
	border: none;
	cursor: pointer;
	position: relative;
	border-radius: 100%;
}

.bell-button:hover {
	background: none;
	/* Annuler le changement de fond */
	/* Annulez également toute autre propriété CSS qui pourrait être modifiée au survol */
}

.notifications-popup {
	position: absolute;
	top: 80px;
	right: 20px;
	border: 1px solid #ccc;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	background-color: white;
	width: 400px;
	max-height: 400px;
	overflow: auto;
	z-index: 1000;
	border-radius: 10px;
}

.notification-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	border-bottom: 1px solid #eee;
	cursor: pointer;
}

.notification-content {
	flex-grow: 1;
	/* Permet au contenu de grandir et de remplir l'espace disponible */
}

.notification-title {
	margin: 0;
	margin-bottom: 0.5rem;
	/* Ajoutez un peu d'espace entre le titre et la description */
}

.notification-description {
	margin: 0;
	font-size: 1rem;
	font-weight: 300 !important;
}

.notification-unread-indicator {
	flex-shrink: 0;
	/* Empêche le point rouge de rétrécir */
	height: 15px;
	width: 15px;
	border-color: black;
	border-width: 2px;
	border-style: solid;
	background-color: red;
	border-radius: 50%;
	margin-left: 5px;
	margin-right: 5px;
}

.view-more-link {
	display: block;
	text-align: center;
	padding: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	font-weight: 300 !important;
	text-decoration: none;
	color: #676767;
}

.view-more-link:hover {
	text-decoration: underline;
}

.notification-list {
	max-height: 350px;
	/* Hauteur ajustée pour tenir compte du texte 'View more' */
	overflow-y: auto;
}

.no-notifications-message {
	padding: 25px 0px 25px 0px;
	text-align: center;
	color: #676767;
	font-weight: 300 !important;
}

.notification-link {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	text-decoration: none;
	color: inherit;
	/* Conserve la couleur de texte par défaut */
}

.notification-link:hover {
	text-decoration: none;
	/* Pas de changement au survol */
}

.notification-bell {
	position: relative;
	animation-name: col;
	animation-duration: 25s;
	animation-iteration-count: infinite;
}

.btn-bell {
	animation-name: bell-ring;
	animation-duration: 25s;
	animation-iteration-count: infinite;
}

.my-btn-border {
	opacity: 0;
	position: absolute;
	border-radius: 50%;
	top: 15%;
	left: 70%;
	width: 100%;
	aspect-ratio: 1/1;
	border: 1px solid #ef7575 !important;
	animation-name: bord-pop;
	animation-duration: 25s;
	animation-iteration-count: infinite;
	box-shadow: 2px 2px 5px #ccc, -2px -2px 5px #ccc;
}

/* @keyframes bord-pop {
	0% {
		transform: translate(-50%, -50%);
		opacity: 0;
	}

	0.66% {
		transform: translate(-50%, -50%) scale(1.6);
		opacity: 0.1;
	}

	1.33% {
		transform: translate(-50%, -50%) scale(1.9);
		opacity: 0.4;
	}

	5% {
		opacity: 0;
	}

	100% {
		transform: translate(-50%, -50%);
		opacity: 0;
	}
} */

@keyframes col {
	0% {
		transform: scale(1) translate(0, 0);
	}

	0.66% {
		transform: scale(1.1) translate(0, 0);
	}

	25% {
		transform: scale(1) translate(0, 0);
	}

	100% {
		transform: scale(1) translate(0, 0);
	}
}

@keyframes bell-ring {
	0% {
		transform: rotate(0deg);
	}

	0.33%,
	0.99% {
		transform: rotate(25deg);
	}

	0.66%,
	1.32% {
		transform: rotate(-25deg);
	}

	1.66% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(0deg);
	}
}
</style>
