<template>
	<div class="help-menu-container" @click.stop="toggleHelpMenu">
		<help-icon class="help-icon"></help-icon>
		<ul v-if="menuOpen" class="dropdown-menu">
			<li><a href="https://semactic.notion.site/49406c1504814f8285e794f5bd39a170?v=7175c6e74e624578909a4dac84b398a5&pvs=4" target="_blank" id="menu_access_documentation_click">Access documentation</a></li>
			<li><a @click="chaton" id="menu_lets_chat_click">Let's chat !</a></li>
		</ul>
	</div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import helpIcon from '../icons/svg/help_icon.vue'; // Assume you have a help icon component
import { Crisp } from 'crisp-sdk-web';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/store/global/auth';
import { useMarketStore } from '@/store/global/market';
const marketStore = useMarketStore();
const { selectedMarket } = storeToRefs(marketStore);

const userStore = useAuthStore();
const { user } = storeToRefs(userStore);

let menuOpen = ref(false);

function toggleHelpMenu() {
	menuOpen.value = !menuOpen.value;
}

Crisp.configure('4b5b83f0-3f5c-4406-a4fa-5a0a11ebc3c7', {
	autoload: false,
});

const chaton = () => {
	Crisp.load();
	$crisp.push(['do', 'chat:open']);
	$crisp.push(['set', 'user:email', [user.value.email]]);
};
const closeMenu = event => {
	if (!event.target.closest('.help-menu-container')) {
		menuOpen.value = false;
	}
};

onMounted(() => {
	document.addEventListener('click', closeMenu);
});

onBeforeUnmount(() => {
	document.removeEventListener('click', closeMenu);
});

// Close the menu when the user clicks outside of it

document.addEventListener('click', event => {
	if (!event.target.closest('.help-menu-container')) {
		menuOpen.value = false;
	}
});
</script>

<style scoped>
.help-menu-container {
	margin-right: 25px; /* Adjust this value based on your exact spacing needs */
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.dropdown-menu {
	position: absolute;
	top: 2.5rem;
	left: 0px;
	width: 200px;
	list-style: none;
	padding: 0;
	margin: 0;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 9px;
	z-index: 999;
}

.dropdown-menu li {
	padding: 15px 1rem;
}

.dropdown-menu li:hover {
	background-color: #e6f7ff;
}

.dropdown-menu a {
	text-decoration: none;
	display: block;
	color: inherit;
}
</style>
