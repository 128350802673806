<template>
	<svg class="icon" version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20 20" style="enable-background: new 0 0 20 20" xml:space="preserve">
		<polygon points="7.9,14.8 10.8,11.9 2.8,3.9 3.9,2.8 11.9,10.8 14.8,7.9 17.2,17.2 " />
	</svg>
</template>

<style lang="scss" scoped>
.icon {
	width: 100%;
	aspect-ratio: 1/1;
	fill: currentColor;
}
</style>
