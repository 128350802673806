<template>
	<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="34" height="34" x="0" y="0" viewBox="0 0 431.855 431.855" style="enable-background: new 0 0 512 512" xml:space="preserve" class="">
		<circle r="215.9275" cx="215.9275" cy="215.9275" fill="#000000" shape="circle" transform="matrix(0.8,0,0,0.8,43.18550109863281,43.18550109863281)"></circle>
		<g transform="matrix(0.7,0,0,0.7,64.7781064820476,64.77824707031252)">
			<path
				d="M215.936 0C96.722 0 .008 96.592.008 215.814c0 119.336 96.714 216.041 215.927 216.041 119.279 0 215.911-96.706 215.911-216.041C431.847 96.592 335.214 0 215.936 0zm15.387 335.962c-5.015 4.463-10.827 6.706-17.411 6.706-6.812 0-12.754-2.203-17.826-6.617-5.08-4.406-7.625-10.575-7.625-18.501 0-7.031 2.463-12.949 7.373-17.745 4.91-4.796 10.933-7.194 18.078-7.194 7.031 0 12.949 2.398 17.753 7.194 4.796 4.796 7.202 10.713 7.202 17.745-.009 7.812-2.521 13.95-7.544 18.412zm62.533-155.028c-3.853 7.145-8.429 13.306-13.737 18.501-5.292 5.194-14.81 13.924-28.548 26.198-3.788 3.463-6.836 6.503-9.12 9.12-2.284 2.626-3.991 5.023-5.105 7.202a34.697 34.697 0 0 0-2.593 6.535c-.61 2.17-1.528 5.999-2.772 11.469-2.113 11.608-8.754 17.411-19.915 17.411-5.804 0-10.681-1.894-14.656-5.69-3.959-3.796-5.934-9.429-5.934-16.907 0-9.372 1.455-17.493 4.357-24.361 2.886-6.869 6.747-12.892 11.543-18.086 4.804-5.194 11.274-11.356 19.427-18.501 7.145-6.251 12.307-10.965 15.485-14.144a47.87 47.87 0 0 0 8.031-10.632c2.187-3.91 3.26-8.145 3.26-12.721 0-8.933-3.308-16.46-9.957-22.597-6.641-6.137-15.209-9.21-25.703-9.21-12.282 0-21.321 3.097-27.125 9.291-5.804 6.194-10.705 15.314-14.729 27.369-3.804 12.616-11.006 18.923-21.598 18.923-6.251 0-11.526-2.203-15.826-6.609-4.292-4.406-6.438-9.177-6.438-14.314 0-10.6 3.406-21.346 10.21-32.23 6.812-10.884 16.745-19.899 29.807-27.036 13.054-7.145 28.296-10.722 45.699-10.722 16.184 0 30.466 2.991 42.854 8.966 12.388 5.966 21.963 14.087 28.718 24.361 6.747 10.266 10.128 21.427 10.128 33.482.016 9.471-1.91 17.787-5.763 24.932z"
				style=""
				fill="#ffc02e"
				data-original="#010002"
				opacity="1"
				class=""
			></path>
		</g>
	</svg>
</template>

<style lang="scss" scoped>
.icon {
	width: 100%;
	aspect-ratio: 1/1;
}
</style>
