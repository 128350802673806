<template>
	<LoadFeaturePermissions v-if="shouldRenderLoadFeaturePermissions" />
	<maintenanceBanner />
	<Notification />
	<Toast position="top-center" style="width: 500px" class="text-center" />
	<div id="app">
		<section v-if="user.isLoggedIn" id="sideBar">
			<sideBar />
		</section>
		<section id="main">
			<suspense v-if="user.isLoggedIn">
				<topMenu />
				<template #fallback>
					<TopMenuSkeletton />
				</template>
			</suspense>
			<RouterView />
		</section>
	</div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import sideBar from './components/utilities/layout/sideBar.vue';
import TopMenuSkeletton from './components/utilities/layout/topMenuSkeletton.vue';
import topMenu from './components/utilities/layout/topMenu.vue';
import Notification from '@/components/shared/Notification.vue';

import Toast from 'primevue/toast';
import './newrelic';

import LoadFeaturePermissions from '@/components/utilities/loads/loadFeaturePermissions.vue';
import maintenanceBanner from '@/components/utilities/layout/maintenanceBanner.vue';

import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/store/global/auth';
import { useBetaStore } from '@/store/global_settings/beta';
import { useMarketStore } from '@/store/global/market';
import { useProfileStore } from '@/store/global/profile.js';

const betaStore = useBetaStore();
const marketStore = useMarketStore();
const profile = useProfileStore();
const user = useAuthStore();
const { selectedProjectPlan } = storeToRefs(betaStore);
const { projects, selectedProject, markets, selectedMarket } = storeToRefs(marketStore);
const { access } = storeToRefs(user);
const { user_name, user_image } = storeToRefs(profile);

window.dataLayer = window.dataLayer || [];

onMounted(() => {
    storeUserToDataLayer();
});

const storeUserToDataLayer = () => {

    if (shouldRenderLoadFeaturePermissions.value) {
        const user_obj = user.getUser().then(user_obj => {
            window.dataLayer.push({ user: [{ id: user_obj.id, name: user_name.value, access: access.value, email: user_obj.email, language: user_obj.language, plan: selectedProjectPlan.value, project: selectedProject.value, market: selectedMarket.value }] });
        });
    }
    
    // log user from dataLayer
    // dataLayer.forEach(item => {
    //     if (item.user) {
    //         console.log(item.user);
    //     }
    // });
};

const route = useRoute();
const path = ref(window.location.pathname);
newrelic.addPageAction('pageViewed', { page: path });

const shouldRenderLoadFeaturePermissions = computed(() => {
	return route.meta.isAuth == true;
});

watch(() => route.path, newPath => {

	path.value = newPath;
    storeUserToDataLayer();
}, 
{ immediate: true });

</script>

<style lang="scss" scoped>
#app {
	display: flex;
	min-width: 98vw;
	max-width: 100%;
	min-height: 100vh;
	max-height: 100%;
	background-color: #f4f3eb;
}
#sideBar {
	width: 5%;
	min-width: 100px;
	min-height: 100vh;
}
#main {
	margin-right: 2rem;
	width: 92%;
	min-height: 100vh;
}
</style>
<style lang="scss">
.no-scroll {
	overflow: hidden;
}
</style>