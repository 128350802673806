import { defineStore } from 'pinia';
import axios from 'axios';

export const useGraphTop10CompStore = defineStore('graphTop10Comp', {
	state: () => ({
		dataSet: {},
		labels: [],
		clientUrl: '',
	}),
	getters: {
		getChartSeries(state) {
			return [
				{
					// type: 'bar',
					// name: 'Appearances on same keywords',
					data: state.dataSet
						.sort((a, b) => b.domain_count - a.domain_count)
						.map(item => {
							return item.domain_count;
						}),
				},
			];
		},
		getChartXAxis(state) {
			return state.dataSet.map(item => {
				// regex to remove http:// or https:// and or www.

				return item.domain_url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
			});
		},
		getClientUrl(state) {
			return state.clientUrl;
		},
	},
	setters: {
		setLabels(state, labels) {
			state.labels = labels;
		},
	},
	actions: {
		async fetchGraphData(selectedMarket, selectedProject, labels = []) {
			const options = {
				market_id: selectedMarket,
				project_id: selectedProject,
				labels: labels,
			};
			const response = await axios.post('vuejs/top10_organic_competitors', options, { withCredentials: true });
			this.dataSet = response.data.data;
			this.clientUrl = response.data.client_url;
		},
	},
	persist: false,
});
