<template>
	<div class="dropdown" ref="dropdown">
		<div class="dropdown-selected" @click="toggleOpen">
			<input
				class="dropdown-filter"
				type="text"
				v-model="searchTerm"
				:placeholder="selectedItemName"
				@input="updateFilter"
				@keydown="handleKeydown"
				:data-selected-id="selectedItem"
			/>
			<i :class="['dropdown-icon', isOpen ? 'icon-up' : 'icon-down']"></i>
		</div>
		<div v-show="isOpen" class="dropdown-options">
			<div class="dropdown-options-item" ref="options">
				<div v-for="(item, index) in filteredOptions" :key="item.id">
					<div
						class="item"
						:data-id="item.id"
						:class="{ 'dropdown-option-selected': selectedItem === item.id, 'dropdown-option-highlighted': highlightedIndex === index }"
						@click="selectOption(item)"
					>
						{{ item.name }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, defineProps, watchEffect, onMounted, onUnmounted } from 'vue';
const { emit } = getCurrentInstance();

const props = defineProps({
	options: {
		type: Array,
		required: true,
	},
	selected: {
		type: [String, Number],
		default: null,
	},
	width: {
		type: String,
		default: '100%',
	},
});

const selectedItem = ref(props.selected);
const isOpen = ref(false);
const highlightedIndex = ref(-1);
const searchTerm = ref('');

watchEffect(() => {
	selectedItem.value = props.selected;
});

const selectedItemName = computed(() => {
	const selectedOption = props.options.find(option => option.id == selectedItem.value);
	return selectedOption ? selectedOption.name : '';
});

const filteredOptions = computed(() => {
	const options = props.options.filter(option => {
		const name = option.name.toLowerCase();
		const term = searchTerm.value.toLowerCase();
		return name.includes(term);
	});

	// Reset the highlighted index when options change
	highlightedIndex.value = -1;

	return options;
});

function toggleOpen() {
	if (isOpen.value) {
		isOpen.value = false;
	} else {
		isOpen.value = true;
		const index = filteredOptions.value.findIndex(option => option.id === selectedItem.value);
		highlightedIndex.value = index !== -1 ? index : -1; // Set the highlighted index to the selected item
	}
}

function selectOption(item) {
	selectedItem.value = item.id;
	isOpen.value = false;
	searchTerm.value = '';
	emit('changeItem', item.id);
}

function handleArrowKey(direction) {
	if (!isOpen.value) {
		isOpen.value = true;
	}

	let step;
	if (direction === 'down') {
		step = 1; // Move down by 1
	} else if (direction === 'up') {
		step = -1; // Move up by 1
	} else if (direction === 'right') {
		step = 5; // Move right by 5
	} else if (direction === 'left') {
		step = -5; // Move left by 5
	}

	highlightedIndex.value = (highlightedIndex.value + step + filteredOptions.value.length) % filteredOptions.value.length;

	// Ensure the highlighted item is visible
	scrollToHighlightedItem();
}

function scrollToHighlightedItem() {
	const optionsElement = document.querySelectorAll('.dropdown-options .item')[highlightedIndex.value];
	if (optionsElement) {
		optionsElement.scrollIntoView({ block: 'nearest', inline: 'nearest' });
	}
}

function handleEnterKey() {
	if (highlightedIndex.value >= 0 && highlightedIndex.value < filteredOptions.value.length) {
		selectOption(filteredOptions.value[highlightedIndex.value]);
	}
}

function handleKeydown(event) {
	if (event.key === 'ArrowDown') {
		event.preventDefault();
		handleArrowKey('down');
	} else if (event.key === 'ArrowUp') {
		event.preventDefault();
		handleArrowKey('up');
	} else if (event.key === 'ArrowRight') {
		event.preventDefault();
		handleArrowKey('right');
	} else if (event.key === 'ArrowLeft') {
		event.preventDefault();
		handleArrowKey('left');
	} else if (event.key === 'Enter') {
		handleEnterKey();
	}
}

function updateFilter(event) {
	isOpen.value = true;
	searchTerm.value = event.target.value;
	highlightedIndex.value = -1; // Reset highlight when typing
}

const handleClickOutside = event => {
	if (!event.target.closest('.dropdown')) {
		isOpen.value = false;
		searchTerm.value = '';
		highlightedIndex.value = -1;
	}
};

onMounted(() => {
	document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
	document.removeEventListener('click', handleClickOutside);
});

</script>

<style lang="scss" scoped>
.dropdown {
	min-width: v-bind(width);
	max-width: v-bind(width);
	position: relative;
	cursor: pointer;
	user-select: none;
	margin: 0 1.5rem 0 0;
}

.dropdown-selected {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	background-color: white;
	padding: 8px;
	border: 1px solid black;
	border-radius: 30px;
	color: black;
	font-weight: 500;
	font-size: 0.9rem;
}

.dropdown-filter {
	width: 100%;
	padding: 4px 0 4px 1rem;
	border: none;

	&::placeholder {
		color: black;
		font-weight: 500;
		opacity: 1;
	}
	&:focus {
		outline: none;
	}
}

.dropdown-icon {
	margin-right: 1.2rem;
	width: 16px;
	height: 16px;
	display: inline-block;
	background-image: url('https://icons.veryicon.com/png/o/miscellaneous/zol-m-station/icon-arrow-up-2.png');
	background-repeat: no-repeat;
	transform: rotate(-180deg);
	background-size: contain;
	transition: transform 0.2s ease-in-out;
}

.icon-up {
	transform: rotate(0deg);
}

.dropdown-options {
	position: absolute;
	background-color: #fff;
	border: 1px solid #ccc;
	max-height: 260px;
	overflow-y: auto;
	border-radius: 9px;
	width: 100%;
	z-index: 999;
}

.dropdown-option-selected {
	background-color: #e6f7ff;
}
.item {
	padding: 0.3rem 1rem;

	&:hover {
		background-color: #e6f7ff;
	}
}

.dropdown-option-highlighted {
  	background-color: var(--jaune-clair-semactic);
}
</style>
