<template>
	<div v-if="value == 'No Data'" :class="color" class="tagg" style="width: 8rem; color: black; justify-content: space-around">
		<img :src="icon" />
		<p>No Data</p>
	</div>
	<div v-else :class="color" class="tagg">
		<img :src="icon" />
		<p style="margin-left: 0.35rem; color: black">
			{{ value }}
		</p>
	</div>
</template>

<!-- CustomTag.vue -->
<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
	position: Number,
	last_position: Number,
});

const icon = computed(() => {
	if (props.last_position === null) {
		return new URL('/src/assets/icon/delete.svg', import.meta.url);
	}
	if (props.position === props.last_position) {
		return new URL('/src/assets/icon/equal.svg', import.meta.url);
	}
	if (props.position > props.last_position) {
		return new URL('/src/assets/icon/down-arrow.svg', import.meta.url);
	}
	if (props.position < props.last_position) {
		return new URL('/src/assets/icon/up-arrow.svg', import.meta.url);
	}
});

const color = computed(() => {
	if (props.last_position === null) {
		return 'red';
	}
	if (props.position === props.last_position) {
		return 'yellow';
	}
	if (props.position > props.last_position) {
		return 'red';
	}
	if (props.position < props.last_position) {
		return 'green';
	}
});

const value = computed(() => {
	if (props.last_position === null) {
		return 'No Data';
	}
	if (props.position === props.last_position) {
		return '';
	}
	if (props.position > props.last_position) {
		return (props.position - props.last_position).toString();
	}
	if (props.position < props.last_position) {
		return (props.last_position - props.position).toString();
	}
});
</script>

<style lang="scss">
.pi-arrow-right-arrow-left {
	padding-left: 4.5px;
}
.tagg {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0.375rem;
	padding: 0.5rem 0 0.4rem;
	margin-left: 0.3rem;
	width: 2.5rem;
	height: 1.5rem;
	font-size: 0.9rem;
	transform: scale(0.75);

	img {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 0.7rem;
		aspect-ratio: 1/1;
	}

	&.red {
		background-color: var(--rouge-clair-semactic);
	}
	&.yellow {
		background-color: var(--jaune-semactic);
	}
	&.green {
		background-color: var(--vert-semactic);
	}
}
</style>
