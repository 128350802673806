<template>
	<header>
		<div class="flex">
			<div style="position: relative">
				<FilteredSelect :options="projects" :selected="selectedProject" width="280px"
					@changeItem="changeProject" data-cy="tm_select_project"></FilteredSelect>
				<div style="position: absolute; top: -1.5rem; left: 0" v-if="super_admin.show_market_id">
					{{ selectedProject }}
				</div>
			</div>

			<div style="position: relative">
				<FilteredSelect :options="markets" :selected="selectedMarket" width="140px" @changeItem="changeMarket"
					data-cy="tm_select_market"></FilteredSelect>
				<div style="position: absolute; top: -1.5rem; left: 0" v-if="super_admin.show_market_id">
					{{ selectedMarket }}
				</div>
			</div>
		</div>
		<Profile />
	</header>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import FilteredSelect from '../forms/FilteredSelect.vue';
import Profile from '../profile/profile.vue';

import { jam } from "@jam.dev/sdk";

import { useMarketStore } from '@/store/global/market';
import { useAuthStore } from '@/store/global/auth';
import { useProfileStore } from '@/store/global/profile.js';
import { useKeywordsStore } from '@/store/visibility/keywords';
import { storeToRefs } from 'pinia';

const KeywordsStore = useKeywordsStore();
const marketStore = useMarketStore();
const authStore = useAuthStore();
const { projects, selectedProject, markets, selectedMarket } = storeToRefs(marketStore);
const { super_admin } = storeToRefs(authStore);

const profile = useProfileStore();
const { user_name, user_image, activity_data } = storeToRefs(profile);

const dashboard_dates = JSON.parse(localStorage.getItem('dashboardSelectedDates'));
const visibility_dates = JSON.parse(localStorage.getItem('visibilitySelectedDates'));

window.dataLayer = window.dataLayer || [];
function changeProject(id) {
	marketStore.selectProject(id);
	KeywordsStore.setLoading(true);
	location.reload();
}

function changeMarket(id) {
	marketStore.selectMarket(id);
	KeywordsStore.setLoading(true);
	location.reload();
}

if (typeof selectedProject.value == 'object') {
	let key1 = '_vts' in selectedProject.value;
	let key2 = 'isTrusted' in selectedProject.value;

	if (key1 || key2) {
		selectedProject.value = 'na';
		location.reload();
	}
}

if (typeof selectedMarket.value == 'object' && selectedMarket.value != null) {
	let key1 = '_vts' in selectedProject.value;
	let key2 = 'isTrusted' in selectedProject.value;

	if (key1 || key2) {
		selectedProject.value = null;
		location.reload();
	}
}

function getItemById(items, id) {
	return items.find(item => item.id === id) || null;
}

watch([selectedProject, selectedMarket], ([newSelectedProject, newSelectedMarket]) => {
	const projectItem = getItemById(projects.value, newSelectedProject);
	const marketItem = getItemById(markets.value, newSelectedMarket);

	jam.metadata(() => {
		return {
			user: user_name ? { id: activity_data.value.user_id, name: user_name.value, image: user_image.value } : null,
			selectedProject: projectItem ? { id: projectItem.id, name: projectItem.name } : null,
			selectedMarket: marketItem ? { id: marketItem.id, name: marketItem.name } : null,
			dashboard_dates: dashboard_dates ? { from: dashboard_dates['from'], to: dashboard_dates['to'] } : null,
			visibility_dates: visibility_dates ? { from: visibility_dates['from'], to: visibility_dates['to'] } : null,
		};
	});
}, { immediate: true });

onMounted(async () => {
	await marketStore.fetchData();

	if (!authStore.isLoggedIn) {
		marketStore.selectedMarket = null;
		localStorage.removeItem('kpi');
		localStorage.removeItem('profile');
		localStorage.removeItem('market');
		localStorage.removeItem('auth');
	}
});
</script>
