<template>
	<svg class="icon" width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.948975" y="11" width="6" height="10" fill="currentColor" />
		<rect x="16.3364" y="5.6853" width="5.68533" height="15.6347" fill="currentColor" />
		<rect x="8.51904" width="5.68533" height="21.32" fill="currentColor" />
	</svg>
</template>

<style lang="scss" scoped>
.icon {
	width: 100%;
	aspect-ratio: 1/1;
}
</style>
