<template>
	<div class="alpha-wrapper" v-if="authStore.hasAccess('super_admin')">
		<slot></slot>
		<!-- Place your content here -->
		<div class="alpha-indicator">
			<img src="@/assets/icon/alphaPin.svg" alt="Alpha" />
		</div>
	</div>
</template>

<script setup>
import { useAuthStore } from '@/store/global/auth';
const authStore = useAuthStore();
</script>

<style scoped>
.alpha-wrapper {
	position: relative;
	outline: 5px solid var(--bleu-clair-semactic);
	border: 0px solid transparent;
	border-radius: 16px;
	padding: 2rem;
	/* background-color: var(--bleu-clair-clair-semactic); */
}

.alpha-indicator {
	position: absolute;
	top: -15px;
	right: -17px;
}

.alpha-indicator img {
	width: 80px;
	height: 80px;
}
</style>
