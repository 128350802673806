// src/utils/jwt.js
import CryptoJS from "crypto-js";

/**
 * Decode a JWT to extract its payload.
 * @param {string} token - The JWT token.
 * @returns {object} - Decoded payload.
 */
export function decodeJWT(token) {
	if (!token) return null;

	try {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(
			atob(base64)
				.split('')
				.map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
				.join('')
		);

		return JSON.parse(jsonPayload);
	} catch (error) {
		console.error('Invalid JWT token:', error);
		return null;
	}
}
