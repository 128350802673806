<template>
	<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="26" height="26" x="0" y="0" viewBox="0 0 51.913 51.913" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M21.367 10.71h-.703c-.824 0-1.491.612-1.491 1.368v5.794h3.686v-5.794c-.001-.755-.668-1.368-1.492-1.368zM31.249 10.71h-.704c-.822 0-1.49.612-1.49 1.368v5.794h3.687v-5.794c-.002-.755-.669-1.368-1.493-1.368z" fill="currentColor" opacity="1" data-original="#000000" class=""></path><path d="M25.957.577C11.644.577 0 12.221 0 26.534c0 6.91 2.684 13.413 7.558 18.311a26.033 26.033 0 0 0 10.329 6.366 2.502 2.502 0 0 0 3.153-1.6 2.498 2.498 0 0 0-1.6-3.152 21.043 21.043 0 0 1-8.337-5.142 20.824 20.824 0 0 1-6.102-14.783c0-11.556 9.401-20.957 20.957-20.957s20.957 9.401 20.957 20.957c0 5.6-2.179 10.859-6.134 14.816-1.519 1.362-5.286 4.104-7.549 4.104-1.162 0-2.03-.366-2.736-1.15-1.789-1.993-2.182-6.028-2.165-8.84 4.339-.219 7.793-3.796 7.793-8.188v-7.78H15.79v7.78c0 4.307 3.318 7.832 7.537 8.177-.026 3.35.444 8.84 3.439 12.185 1.652 1.845 3.89 2.818 6.466 2.818 4.836 0 10.342-4.885 10.952-5.44l.08-.076c4.934-4.907 7.648-11.442 7.648-18.402C51.914 12.221 40.27.577 25.957.577z" fill="currentColor" opacity="1" data-original="#000000" class=""></path></g></svg>
</template>

<style lang="scss" scoped>
.icon {
	width: 100%;
	aspect-ratio: 1/1;
}
</style>
