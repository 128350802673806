import { defineStore } from 'pinia';

export const useDashboardStore = defineStore('dashboard-store', {
	state: () => ({
		filter: false,
	}),
	getters: {
		getFilter(state) {
			return state.filter;
		},
	},
	persist: true,
});
