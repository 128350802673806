import { defineStore } from 'pinia';
import axios from 'axios';
import { useNotificationStore } from '@/store/global/notification';

export const useNotificationBellStore = defineStore('notificationBell', {
	state: () => ({
		listing: [],
		isLoading: true,
	}),
	actions: {
		async fetchData(selectedMarket) {
			this.isLoading = true;
			const response = await axios.post(
				'vuejs/get_notifications_listing',
				{
					market_id: selectedMarket,
				},
				{ withCredentials: true },
			);

			this.isLoading = false;
			this.listing.splice(0, this.listing.length, ...response.data.data);
			return response.data.data;
		},
		async markAsRead(notificationId) {

			const response = await axios.post(
				'vuejs/mark_notification_as_read',
				{
					notification_id: notificationId,
				},
				{ withCredentials: true },
			);

			this.fetchData();
			return response.data;
		}
	},
	persist: false,
});
