/**
 * A utility function to match input strings or numbers with values.
 * It handles both string matching (with optional case sensitivity and partial matching)
 * and numeric conditions like <, >, =.
 * 
 * @param {string|number} input - The input string or number to match.
 * @param {string|number} value - The value from the item to compare with.
 * @param {Object} options - Optional configuration for matching behavior.
 * @param {boolean} options.caseSensitive - Should the match be case-sensitive? Default is false.
 * @param {boolean} options.partial - Should it allow partial matching for strings? Default is true.
 * @param {string} options.condition - The numeric condition (e.g., '>', '<', '=') for numeric comparisons.
 * 
 * @returns {boolean} - Returns true if there's a match, otherwise false.
 */
export function filterMatch(input, value, options = { caseSensitive: false, partial: true, condition: null }) {
	// Handle undefined or empty input or value
	if (!input || !value) return false;

	// If a numeric condition is specified, assume we're working with numbers
	if (options.condition) {
		const inputNumber = parseFloat(input);
		const valueNumber = parseFloat(value);

		// Ensure we have valid numbers
		if (isNaN(inputNumber) || isNaN(valueNumber)) return false;

		// Handle different conditions for numeric comparisons
		switch (options.condition) {
			case '>':
				return valueNumber > inputNumber;
			case '>=':
				return valueNumber >= inputNumber;
			case '<':
				return valueNumber < inputNumber;
			case '<=':
				return valueNumber <= inputNumber;
			case '=':
				return valueNumber === inputNumber;
			default:
				return false;
		}
	}

	// Handle string comparisons (partial or exact match)
	let inputString = options.caseSensitive ? input : input.toLowerCase();
	let valueString = options.caseSensitive ? value : value.toLowerCase();

	if (options.partial) {
		return valueString.includes(inputString);  // Partial match
	}

	return valueString === inputString;  // Exact match
}