import { defineStore } from 'pinia';
import axios from 'axios';

export const useMarketStore = defineStore('market', {
	state: () => ({
		selectedMarket: null,
		selectedProject: 'na',
		markets: [],
		projects: [],
	}),
	getters: {
		projectName(state) {
			const project = state.projects.find(p => p.id === state.selectedProject);
			return project ? project.name : null;
		},

		marketName(state) {
			const market = state.markets.find(m => m.id === state.selectedMarket);
			return market ? market.name : null;
		},
	},
	actions: {
		async fetchData() {
			const response = await axios.post(
				'vuejs/get_projects_markets',
				{
					project_id: this.selectedProject,
				},
				{ withCredentials: true },
			);

			this.projects = response.data.data.projects;
			this.markets = response.data.data.markets;

			if ((this.selectedProject === 'na' && this.projects.length > 0) || this.projects.findIndex(elem => elem.id === this.selectedProject) === -1) {
				this.selectedProject = this.projects[0].id;
			}

			if ((this.selectedMarket === null && this.markets.length > 0) || this.markets.findIndex(elem => elem.id === this.selectedMarket) === -1) {
				this.selectedMarket = this.markets[0].id;
			}

			return response.data.data;
		},

		async fetchMarket(project_id) {
			const response = await axios.post(
				'vuejs/get_markets',
				{
					project_id: project_id,
				},
				{ withCredentials: true },
			);

			return response.data.data;
		},

		selectMarket(marketId) {
			this.selectedMarket = marketId;
		},

		selectProject(projectId) {
			this.selectedProject = projectId;
			this.selectedMarket = null;
		},

		// async fetchMarketSettings(marketId) {

		//     const response = await axios.post('/vuejs/settings_page',
		//     {
		//         'market_id': marketId
		//     },
		//     { withCredentials: true });
		//     this.selectedMarketSettings = JSON.parse(response.data.market);

		//     return response.data.data;
		// }
	},
	persist: true,
});
