<template>
	<div class="userProfile">
		<avatar style="width: 100px !important; height: 100%" :href="assigneUserImg()"></avatar>
		<div class="nameUser">{{ userData.first_name }} {{ userData.last_name }}</div>
	</div>
	<div class="userData">
		<div class="progress-bar">
			<div class="finished-tasks" :style="{ width: userData.done_percentage + '%' }" @mousemove="updateTooltip" @mouseenter="showProgressInfo('finished')" @mouseleave="showTooltip = false"></div>
			<div class="abandoned-tasks" :style="{ width: userData.abandoned_percentage + '%' }" @mousemove="updateTooltip" @mouseenter="showProgressInfo('abandoned')" @mouseleave="showTooltip = false"></div>
			<div class="active-tasks" :style="{ width: userData.active_percentage + '%' }" @mousemove="updateTooltip" @mouseenter="showProgressInfo('active')" @mouseleave="showTooltip = false"></div>
			<div class="total-tasks" :style="{ width: 100 - userData.done_percentage - userData.abandoned_percentage - userData.active_percentage + '%' }" @mousemove="updateTooltip" @mouseenter="showProgressInfo('total')" @mouseleave="showTooltip = false"></div>
		</div>
		<div v-if="showTooltip" class="tooltip" :class="selectedProgressInfo" :style="{ top: tooltipY + 'px', left: tooltipX + 'px' }">
			<div v-if="selectedProgressInfo == 'active'">{{ t('in_progress') }}: {{ userData.active }}</div>
			<div v-else-if="selectedProgressInfo == 'abandoned'">{{ t('abandoned') }}: {{ userData.abandoned }}</div>
			<div v-else-if="selectedProgressInfo == 'finished'">{{ t('done') }}: {{ userData.done }}</div>
			<div v-else>{{ t('assigned') }}: {{ userData.total - (userData.active + userData.abandoned + userData.done) }}</div>
		</div>
		<p class="mt-2" style="text-align: center; color: var(--gris-fonce-semactic);">total: {{ userData.total }}</p>
	</div>
</template>

<script setup>
import { ref } from 'vue';
import avatar from '@/components/utilities/profile/avatar.vue';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const props = defineProps({
	userData: Object,
});

const assigneUserImg = () => {
	if (props.userData?.avatar != null) {
		return props.userData.avatar;
	} else {
		return 'https://ui-avatars.com/api/?name=' + props.userData.first_name + '+' + props.userData.last_name + '&background=random';
	}
};

// Progress info
const tooltipX = ref(0);
const tooltipY = ref(0);
const showTooltip = ref(false);
const selectedProgressInfo = ref('');

const updateTooltip = event => {
	tooltipX.value = event.clientX;
	tooltipY.value = event.clientY - 60;
};

const showProgressInfo = name => {
	selectedProgressInfo.value = name;
	showTooltip.value = true;
};
</script>

<style lang="scss" scoped>
.userProfile {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.nameUser {
	font-size: 1.2rem;
	font-weight: 100;
	margin-top: 0.5rem;
}

.progress-bar {
	position: relative;
	display: flex;
	height: 0.5rem;
	width: 150px;
	background-color: var(--jaune-semactic);
	border-radius: 0.5rem;
	margin: 1.5rem auto 0;
	overflow: hidden;
}

.finished-tasks {
	background-color: var(--vert-semactic);
	height: 100%;
	border-radius: 0.5rem 0 0 0.5rem;
}

.abandoned-tasks {
	background-color: var(--rouge-semactic);
	height: 100%;
	border-radius: 0 0.5rem 0.5rem 0;
}

.active-tasks {
	background-color: var(--bleu-semactic);
	height: 100%;
	border-radius: 0 0.5rem 0.5rem 0;
}

.tooltip {
	position: fixed;
	padding: 15px;
	background: var(--jaune-semactic);
	border-radius: 15px;
	pointer-events: none;
	z-index: 1000;
	font-weight: 200;

	&::after {
		content: '';
		position: absolute;
		top: 65%;
		left: 0;
		border: 15px solid transparent;
		border-top-color: var(--jaune-semactic);
		transform: rotate(-90deg);
	}

	&.active {
		background-color: var(--bleu-semactic);

		&::after {
			border-top-color: var(--bleu-semactic);
		}
	}

	&.abandoned {
		background-color: var(--rouge-semactic);
		color: white;

		&::after {
			border-top-color: var(--rouge-semactic);
		}
	}

	&.finished {
		background-color: var(--vert-semactic);

		&::after {
			border-top-color: var(--vert-semactic);
		}
	}

	&.assigned {
		background-color: var(--jaune-semactic);

		&::after {
			border-top-color: var(--jaune-semactic);
		}
	}
}
</style>
