<template>
	<svg enable-background="new 0 0 24 24" height="27" viewBox="0 0 24 24" width="27" xmlns="http://www.w3.org/2000/svg">
		<circle cx="12" cy="20" fill="#ffa000" r="3.296" />
		<path d="m21.379 17.912c-1.512-1.278-2.379-3.145-2.379-5.124v-2.788c0-3.86-3.141-7-7-7s-7 3.14-7 7v2.788c0 1.979-.867 3.847-2.388 5.133-.389.332-.612.817-.612 1.329 0 .965.785 1.75 1.75 1.75h16.5c.965 0 1.75-.785 1.75-1.75 0-.512-.223-.997-.621-1.338z" fill="#ffc107" />
		<path d="m12 24c-2.068 0-3.75-1.682-3.75-3.75 0-.414.336-.75.75-.75s.75.336.75.75c0 1.241 1.009 2.25 2.25 2.25s2.25-1.009 2.25-2.25c0-.414.336-.75.75-.75s.75.336.75.75c0 2.068-1.682 3.75-3.75 3.75z" />
		<path
			d="m20.25 21h-16.5c-.965 0-1.75-.785-1.75-1.75 0-.512.223-.997.612-1.33 1.521-1.285 2.388-3.153 2.388-5.132v-2.788c0-3.86 3.14-7 7-7s7 3.14 7 7v2.788c0 1.979.867 3.847 2.378 5.125.399.34.622.825.622 1.337 0 .965-.785 1.75-1.75 1.75zm-8.25-16.5c-3.033 0-5.5 2.467-5.5 5.5v2.788c0 2.421-1.061 4.707-2.91 6.27-.035.03-.09.092-.09.192 0 .136.114.25.25.25h16.5c.136 0 .25-.114.25-.25 0-.1-.055-.162-.088-.19-1.851-1.565-2.912-3.851-2.912-6.272v-2.788c0-3.033-2.467-5.5-5.5-5.5z"
		/>
		<path d="m12 4.5c-.414 0-.75-.336-.75-.75v-3c0-.414.336-.75.75-.75s.75.336.75.75v3c0 .414-.336.75-.75.75z" />
	</svg>
</template>

<style lang="scss" scoped>
.icon {
	width: 100%;
	aspect-ratio: 1/1;
}
</style>
