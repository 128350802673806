import { defineStore } from 'pinia';
import axios from 'axios';

export const useGraphStrengthWeaknessStore = defineStore('graphStrengthWeakness', {
	state: () => ({
		data_start: {},
		data_end: {},
		selectedDates: {},
		all_labels: [],
		selected_labels: [],
		extremum: [],
	}),
	getters: {
		chartSeriesStart(state) {
			return state.data_start;
		},
		chartSeriesEnd(state) {
			return state.data_end;
		},
		startDate(state) {
			return state.selectedDates.from;
		},
		endDate(state) {
			return state.selectedDates.to;
		},
		chartLabels(state) {
			return state.all_labels;
		},
		selectedLabels(state) {
			return state.selected_labels;
		},
		scaleExtremum(state) {
			return state.extremum;
		},
	},
	actions: {
		async fetchData(selectedMarket, selectedProject, selectedLabels = [], filter = 'None') {
			this.selectedDates = JSON.parse(localStorage.getItem('dashboardSelectedDates'));

			const options = {
				market_id: selectedMarket,
				project_id: selectedProject,
				date_range_start: this.selectedDates.from,
				date_range_end: this.selectedDates.to,
			};

			if (selectedLabels.length > 0) {
				options.selected_labels = selectedLabels;
			}

			if (filter !== 'None') {
				options.filter = JSON.stringify(filter);
			}

			const response = await axios.post('vuejs/strength_weakness_chart', options, { withCredentials: true });
			this.data_start = response.data.data_start;
			this.data_end = response.data.data_end;
			this.all_labels = response.data.all_labels;
			this.selected_labels = response.data.selected_labels;

			let extremum_start = [];
			let extremum_end = [];

			if (response.data.data_start.datasets.length > 0) {
				extremum_start = this.findExtremum(response.data.data_start.datasets);
			}

			if (response.data.data_end.datasets.length > 0) {
				extremum_end = this.findExtremum(response.data.data_end.datasets);
			}

			if (extremum_start.length > 0 && extremum_end.length > 0) {
				const e = extremum_start.concat(extremum_end);
				const eMin = Math.min(...e);
				const eMax = Math.max(...e);
				this.extremum = [eMin, eMax];
			} else if (extremum_start.length === 0) {
				this.extremum = extremum_end;
			} else if (extremum_end.length === 0) {
				this.extremum = extremum_start;
			} else {
				this.extremum = [0, 100];
			}

			return response.data;
		},
		findExtremum(array) {
			let mn = [];
			let min = 0;
			let mx = [];
			let max = 0;

			for (let i = 0; i < array.length; i++) {
				mn.push(Math.min(...array[i].data));
				mx.push(Math.max(...array[i].data));
			}

			min = Math.min(...mn);
			max = Math.max(...mx);

			return [min, max];
		},
	},
	persist: false,
});
