<template>
	<div class="beta-wrapper">
		<slot></slot>
		<!-- Place your content here -->
		<div class="beta-indicator">
			<img src="@/assets/icon/betaPin.svg" alt="Beta" />
		</div>
	</div>
</template>

<script setup></script>

<style scoped>
.beta-wrapper {
	position: relative;
	outline: 5px solid var(--bleu-clair-semactic);
	border: 0px solid transparent;
	border-radius: 16px;
	padding: 2rem;
	/* background-color: var(--bleu-clair-clair-semactic); */
}

.beta-indicator {
	position: absolute;
	top: -15px;
	right: -17px;
}

.beta-indicator img {
	width: 80px;
	height: 80px;
}
</style>
