export function applyFilter(source, filter, keyConfig) {

	if (!validateInput(source, filter, keyConfig)) {
		return source;
	}

	// Separate the global search input filter from the rest
	const globalSearchFilter = filter.find(f => f.variable === 'globalSearchInput');
	const otherFilters = filter.filter(f => f.variable !== 'globalSearchInput');

	// Group other filters by variables and operators
	const groupedFilters = groupFiltersByOrOperator(otherFilters);

	// Create the finalResult array by filtering the source
	let finalResult = source.filter(item => {
		// Boolean to track if the item passed all filters
		let matchFound = true; // Start by assuming the item matches

		// Loop through the grouped filters (grouped by variable)
		Object.entries(groupedFilters).forEach(([variable, filters]) => {
			const key = keyConfig[variable]?.key || variable;
			const itemValue = getNestedValue(item, key);

			// Initialize localMatch based on the operator type
			let localMatch = filters.some(filter => filter.appliedOperator === 'or') ? false : true;

			// Apply filters for this variable
			filters.forEach(filter => {
				const { appliedOperator, condition, value: filterValue } = filter;
				const match = checkCondition(itemValue, condition, filterValue);

				// Handle 'or' and 'and' conditions
				if (appliedOperator === 'or') {
					localMatch = localMatch || match; // If any 'or' condition is true, the whole variable passes
				}
				else if (appliedOperator === 'and') {
					localMatch = localMatch && match; // If any 'and' condition fails, the whole variable fails
				}
			});

			// If localMatch is false after evaluating all filters for this variable, the item fails
			if (!localMatch) {
				matchFound = false;
			}
		});

		// If globalSearchInput filter is present, check it against all string-type fields
		if (globalSearchFilter && matchFound) {
			matchFound = applyGlobalSearchFilter(item, globalSearchFilter, keyConfig);
		}

		// Return true if the item matches all filters, false if it doesn't
		return matchFound;
	});

	return finalResult;
}

function applyGlobalSearchFilter(item, globalSearchFilter, keyConfig) {
	const { value: searchValue } = globalSearchFilter;

	// Search through all string-type variables in keyConfig
	return Object.entries(keyConfig).some(([variable, config]) => {
		if (config.type == 'string') {
			const itemValue = getNestedValue(item, config.key);
			return checkCondition(itemValue, 'contains', searchValue);
		}
	});

	// If no match found in any string variable, return false
	return false;
}

function validateInput(source, filter, keyConfig) {
	if (!source || !Array.isArray(source) || source.length === 0) {
		console.error('Error: Source data is missing or empty.');
		return false;
	}

	if (!filter || Object.keys(filter).length === 0) {
		console.error('Error: Filter data is missing or empty.');
		return false;
	}

	if (!keyConfig || Object.keys(keyConfig).length === 0) {
		console.error('Error: Key configuration is missing or empty.');
		return false;
	}

	return true;
}

function groupFiltersByOrOperator(filter) {
	const groupedByVariable = {};

	filter.forEach(item => {
		// If the variable key does not exist, create an array for it
		if (!groupedByVariable[item.variable]) {
			groupedByVariable[item.variable] = [];
		}

		// Add the item to the respective variable group
		// Clone the item and add an appliedOperator without changing the original item
		const newItem = { ...item };

		groupedByVariable[item.variable].push(newItem);
	});

	// Now ensure that if any item in a group has an 'or' operator, all should be treated with 'or'
	Object.entries(groupedByVariable).forEach(([variable, group]) => {
		const hasOrOperator = group.some(item => item.operator === 'or');

		// Set the appliedOperator based on the presence of 'or'
		group.forEach(item => {
			item.appliedOperator = hasOrOperator ? 'or' : 'and';
		});
	});

	return groupedByVariable;
}

function checkCondition(itemValue, condition, value) {
	// Handle the case where itemValue is an array of strings
	if (Array.isArray(itemValue)) {
		// Apply the condition to every element in the array
		return itemValue.some(val => checkCondition(val, condition, value));
	}

	const isNumericComparison = !isNaN(parseFloat(itemValue)) && !isNaN(parseFloat(value));

	switch (condition) {
		case 'contains':
			return String(itemValue).toLowerCase().includes(String(value).toLowerCase());
		case 'equals':
			return isNumericComparison
				? parseFloat(itemValue) === parseFloat(value)
				: String(itemValue).toLowerCase() === String(value).toLowerCase();
		case 'not equals':
			return isNumericComparison
				? parseFloat(itemValue) !== parseFloat(value)
				: String(itemValue).toLowerCase() !== String(value).toLowerCase();
		case 'greater than':
			return isNumericComparison && parseFloat(itemValue) > parseFloat(value);
		case 'less than':
			return isNumericComparison && parseFloat(itemValue) < parseFloat(value);
		case 'start with':
			return String(itemValue).toLowerCase().startsWith(String(value).toLowerCase());
		case 'end with':
			return String(itemValue).toLowerCase().endsWith(String(value).toLowerCase());
		default:
			return false;
	}
}

function getNestedValue(obj, path) {
	let value = obj;
	let keys = path.split('.');

	for (let key of keys) {
		if (key.includes('[') && key.includes(']')) {
			const match = key.match(/\[(\d*)\]/);

			if (match) {
				let arrayKey = key.split('[')[0];

				if (value && value[arrayKey] && Array.isArray(value[arrayKey])) {
					if (match[1] !== "") {
						let index = parseInt(match[1], 10);

						if (value[arrayKey][index] !== undefined) {
							value = value[arrayKey][index];
						}
						else {
							return null;
						}
					}
					else {
						value = value[arrayKey].map(item => {
							if (!item) return null;
							const nextKey = keys[keys.indexOf(key) + 1];
							return item[nextKey];
						}).filter(v => v !== undefined);
						break;
					}
				}
				else if (value && value[arrayKey] === null) {
					return null;
				}
				else {
					return null;
				}
			}
		}
		else {
			if (value && value[key] !== undefined) {
				value = value[key];
			}
			else {
				return null;
			}
		}
	}

	return value;
}