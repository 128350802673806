<template>
	<svg class="icon" width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3.99472 11.7003C1.84958 9.55515 1.84958 6.07718 3.99472 3.93204C6.13986 1.7869 9.61782 1.7869 11.763 3.93204L14.5878 6.75685C14.9583 7.12738 14.9583 7.72812 14.5878 8.09864L8.16132 14.5251C7.79079 14.8956 7.19006 14.8956 6.81953 14.5251L3.99472 11.7003Z" fill="currentColor" />
		<rect x="12.5193" y="7.95044" width="5.07992" height="2.03197" transform="rotate(45 12.5193 7.95044)" fill="currentColor" />
		<rect x="9.64551" y="10.8242" width="5.07992" height="2.03197" transform="rotate(45 9.64551 10.8242)" fill="currentColor" />
		<path d="M22.9808 15.3515C25.1287 17.4994 25.1287 20.9818 22.9808 23.1297C20.8329 25.2776 17.3505 25.2776 15.2026 23.1297L12.0206 19.9477C11.6301 19.5572 11.6301 18.924 12.0206 18.5335L18.3846 12.1695C18.7751 11.779 19.4083 11.779 19.7988 12.1695L22.9808 15.3515Z" fill="currentColor" />
	</svg>
</template>

<style lang="scss" scoped>
.icon {
	width: 100%;
	aspect-ratio: 1/1;
}
</style>
