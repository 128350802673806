<template>
	<svg class="icon" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="27" height="27" x="0" y="0" viewBox="0 0 64 64" style="enable-background: new 0 0 512 512" xml:space="preserve">
		<g>
			<path
				d="M8.5 58a2.006 2.006 0 0 0 2 2H31V33H8.5Zm18-14.75a1 1 0 0 1 2 0v4.5a1 1 0 0 1-2 0ZM8.5 6v25H31V4H10.5a2.006 2.006 0 0 0-2 2Zm18 10.25a1 1 0 0 1 2 0v4.5a1 1 0 0 1-2 0ZM53.5 4H33v27h22.5V6a2.006 2.006 0 0 0-2-2ZM52 20.75a1 1 0 0 1-2 0v-4.5a1 1 0 0 1 2 0ZM33 60h20.5a2.006 2.006 0 0 0 2-2V33H33Zm17-16.75a1 1 0 0 1 2 0v4.5a1 1 0 0 1-2 0Z"
				fill="currentColor"
				opacity="1"
				data-original="#000000"
			></path>
		</g>
	</svg>
</template>

<style lang="scss" scoped>
.icon {
	width: 100%;
	aspect-ratio: 1/1;
}
</style>
